.search-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.search-input {
  max-width: var(--form-max-width);
  position: relative;
}

.search-icon {
  color: var(--input-placeholder-icon-color);
  position: absolute;
  top: 10px;
  right: 12px;
  z-index: 100;
}

.clear-icon {
  color: var(--input-placeholder-icon-color);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  padding: 10px 12px;
  cursor: pointer;
}

.search-dropdown-menu {
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  position: absolute;
  bottom: 0;
  max-width: var(--form-max-width);
  top: 37px;
  padding: 1rem;
  height: 200px;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  z-index: 100;
  width: 100%;
  overflow: auto;
}
