.card {
  --bs-card-spacer-x: 0.5rem;
  --bs-card-spacer-y: 0.6rem;
  --bs-card-title-spacer-y: 0.25rem;
  border: 1px solid var(--border-secondary);
}

.image-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-text {
  color: var(--text-secondary-color);
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.125);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 34px;
  color: var(--overlay-modal-button-color);
  z-index: 10;
}

.hide-tags.card-body {
  padding: 14px 8px;
}

.hide-tags .image-title {
  margin-bottom: 0;
}

.hide-tags .card-text {
  display: none;
}
