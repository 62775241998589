.filters-wrapper {
  margin: 20px 0;
}

.results-found {
  margin: -6px 0 1rem 0;
  text-align: center;
  color: var(--text-secondary-color);
}

.spacer {
  height: 32px;
}
