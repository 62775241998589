.info-box {
  background-color: var(--info-box-bg-color);
  font-size: 14px;
  margin: auto auto 24px auto;
  max-width: 560px;
  padding: 16px;
}

.info-box p {
  margin-bottom: 4px;
}

.info-box ul {
  margin-bottom: 0;
}
