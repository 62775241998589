.view-type-wrapper {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 575px) {
  .view-type-wrapper {
    margin: 1rem 0 0.25rem;
    justify-content: center;
  }
}

.view-type-icon-button-wrapper {
  margin-left: 0.75rem;
  display: flex;
}

.view-type-icon, .view-type-icon-active {
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  color: var(--view-selector-color);
}

.view-type-icon-active {
  color: var(--view-selector-color-active);
}
