.end-reached {
  color: var(--text-secondary-color);
  font-size: 16px;
  margin: 0;
  text-align: center;
}

.end-reached-tiniest {
  color: var(--text-secondary-color);
  font-size: 16px;
  margin: 0.5rem 0 1.25rem;
  text-align: center;
}

.largest-card, .smallest-card {
  margin-bottom: 1.5rem;
}

.tiniest-card {
  margin-bottom: 0.75rem;
  padding: 0 6px;
}

.tiniest-card img {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

/* disable view types on smallest displays */
@media (max-width: 575px) {
  .largest-card {
    flex: 0 0 auto;
    width: 100%;
  }

  .smallest-card {
    flex: 0 0 auto;
    width: 50%;
  }
  
  .tiniest-card {
    flex: 0 0 auto;
    width: 25%;
  }
}
